






























import { Vue, Component, Prop } from 'vue-property-decorator';

import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';

import Loader from '@/shared/resources/components/Loader.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import OnlineOrdersItemsTable from './partials/tables/OnlineOrdersItemsTable.vue';
import OnlineOrderGeneralDetails from './partials/details/OnlineOrderGeneralDetails.vue';
import OnlineOrdersStatusDetails from './partials/details/OnlineOrdersStatusDetails.vue';
import Alert from '@/shared/resources/components/Alert.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';

@Component({
  components: {
    Button,
    Alert,
    OnlineOrdersStatusDetails,
    OnlineOrderGeneralDetails,
    OnlineOrdersItemsTable,
    GridRow,
    GridCol,
    Loader,
  },
})
export default class OnlineOrderDetails extends Vue {
  /**
   * Props
   */
  @Prop() private id!: number;

  /**
   * Data
   */
  private order: OnlineOrderCoreModel | null = null;
  private loading: boolean = false;
  private error: boolean = false;

  /**
   * Display getters
   */
  private get displayDetails(): boolean {
    return !this.loading && !!this.order;
  }

  private get displayLoader(): boolean {
    return this.loading;
  }

  private get displayError(): boolean {
    return this.error;
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    this.getOrder();
  }

  /**
   * Methods
   */
  private async getOrder() {
    this.loading = true;

    try {
      this.order = await OnlineOrderCoreModel
          .apiExt()
          .param('id', this.id)
          .with('statuses')
          .with({
            items: { // TODO change to items
              with: 'product',
            },
          })
          .get();

      this.$emit('get', this.order);
    } catch (ex) {
      // TODO
      this.error = true;
    }

    this.loading = false;
  }
}
