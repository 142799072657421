



























import { Component, Vue } from 'vue-property-decorator';

import MainHeader from '@/shared/resources/components/MainHeader.vue';
import Alert from '@/shared/resources/components/Alert.vue';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import Button from '@/shared/resources/components/buttons/Button.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import OnlineOrderDetails from '@/app/modules/online-orders/components/OnlineOrderDetails.vue';

@Component({
  components: {
    OnlineOrderDetails,
    GridCol,
    GridRow,
    Button,
    MainHeader,
  },
})
export default class OrdersView extends Vue {
  /**
   * Data
   */
  private order: OnlineOrderCoreModel | null = null;

  /**
   * Getters
   */
  private get orderId(): number {
    return Number(this.$route.params.id);
  }

  private get title(): string {
    return this.order ? this.order.number : 'Zamówienie';
  }

  /**
   * Handlers
   */
  private onGet(order: OnlineOrderCoreModel) {
    this.order = order;
  }
}
