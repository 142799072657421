












import { Component, Vue, Prop } from 'vue-property-decorator';

import Alert from '@/shared/resources/components/Alert.vue';
import { bankDataAccountNumber } from '@/shared/lib/config/generalConfig';

@Component({
  components: {
    Alert,
  },
})
export default class AccountNumberAlert extends Vue {
  /**
   * Props
   */
  @Prop({type: Boolean, default: false}) private inline!: boolean;

  /**
   * Getters
   */
  private get bankDataAccountNumber(): string {
    return bankDataAccountNumber;
  }

  /**
   * Display getters
   */
  private get displayTitle(): boolean {
    return !this.inline;
  }
}
