




















import { Vue, Component, Prop } from 'vue-property-decorator';

import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';

import Card from '@/shared/resources/components/cards/Card.vue';
import DataTable from '@/shared/resources/components/tables/data-table/DataTable.vue';
import { DataTableHeader } from 'vuetify';
import TableTemplateInterface from '@/shared/lib/interfaces/TableTemplateInterface';

@Component({
  components: {
    DataTable,
    Card,
  },
})

export default class OnlineOrdersStatusDetails extends Vue {
  /**
   * Props
   */
  @Prop() private order!: OnlineOrderCoreModel;

  /**
   * Data
   */
  private headers: DataTableHeader[] = [
    {
      text: 'Status',
      value: 'status',
    },
    {
      text: 'Data',
      value: 'dateFormatted',
    },
  ];

  private template: TableTemplateInterface = {
    search: false,
    limitSelect: false,
    topPagination: false,
    bottomPagination: false,
    topLimit: false,
    bottomLimit: false,
  };
}
