






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import Card from '@/shared/resources/components/cards/Card.vue';
import Detail from '@/shared/resources/components/details/Detail.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import { PaymentsEnum } from '@/modules/payments/enums/PaymentsEnum';
import AccountNumberAlert from '@/app/components/AccountNumberAlert.vue';

@Component({
  components: {
    AccountNumberAlert,
    Divider,
    Detail,
    Card,
  },
})
export default class OnlineOrderGeneralDetails extends Vue {
  /**
   * Props
   */
  @Prop() private order!: OnlineOrderCoreModel;

  /**
   * Display getters
   */
  private get displayDetailedSummary(): boolean {
    return !this.order.hasFreeDelivery;
  }

  private get displayAccountNumberAlert(): boolean {
    return this.order.payment === PaymentsEnum.BANKWIRE && this.order.shouldBePaid;
  }
}
